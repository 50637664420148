import * as React from 'react'
import { inject, observer } from 'mobx-react'
import * as Base from 'react-festival/build/home/home'
import ImageHeader from '@festival/page-configuration/image-header'
import ActualityList from '@festival/actuality/list'

export interface Props extends Base.Props {

}

export interface State extends Base.State {

}

export class Home extends Base.Home<Props, State> {
    renderContent (): React.ReactNode {
        return (
            <>
                <ImageHeader />
                <ActualityList />
            </>
        )
    }
}

export default inject('pageConfigurationStore')(Home)