import * as React from 'react'
import * as Base from 'react-festival/build/actuality/list'
import { inject } from 'mobx-react'

export class List extends Base.List {

    protected get rowClass (): string {
        return 'row-cols-1 row-cols-sm-2 row-cols-xl-3'
    }
}

export default inject('actualityStore')(List)