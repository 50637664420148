import * as React from 'react'
import {  observer } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import { Link } from '@festival/component/link'
import { Store } from '@festival/actuality/store'
import { Actuality } from '@festival/actuality/store'
import ImageMedia from '@festival/component/image-media'
import {
    Card, CardBody, CardTitle,
    Col
} from 'reactstrap'
import VersionToggler from '@festival/versionable/version-toggler'

export interface Props {
    actuality: Actuality
}

export interface State {

}

export class ListItem extends React.Component<Props, State> {
    render (): React.ReactNode {
        const { actuality } = this.props

        return (
            <Col className="mb-2">
                <Card className="border-0">
                    <VersionToggler container={actuality} className="position-absolute" style={{ top: 0 }}/>
                    <Link
                        href={actuality.active.link}
                        target={actuality.active.target}
                        title={actuality.active.title}
                        >
                        <ImageMedia media={actuality.active.media} name="illustration" version="large" className="border border-primary" alt={actuality.active.alt}/>
                    </Link>
                    <CardBody className="p-1">
                        <Link
                            href={actuality.active.link}
                            target={actuality.active.target}
                            title={actuality.active.title}
                            className="text-primary"
                            >
                            <CardTitle tag="h5" className="m-0 text-truncate">
                                <i className=" mdi mdi-pound mr-2"></i>
                                {actuality.active.title}
                            </CardTitle>
                            <p className="m-0 text-truncate">
                                {actuality.active.contents}
                            </p>
                        </Link>
                    </CardBody>
                </Card>
            </Col>
        )
    }
}

export default observer(ListItem)
